import React from "react";

const Ayuda = () => {
  return (
    <>
      <table>
        <tr>
          <td height="20px">
          </td>
        </tr>
        <tr>
          <td width="70px">
            <img src="imagenes/rosa_colores.png" width="45px"></img>
          </td>
          <td>Rosa de las direcciones por colores: En los gráficos, cada color indica una dirección, coincidiendo con los de la rosa.</td>
        </tr>
      </table>

      {/* <img src="imagenes/ayuda_mar.png" />
      <br></br>
      <img src="imagenes/ayuda_tiempo.png" /> */}
    </>
  );
};

export default Ayuda;
