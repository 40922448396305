export const Configuracion = {
  configuracion: [
    {
      periodo: 3,

      // CONFIG GRAFICA _____________________
      ancho_limite: "420px",
      espacio_intermedio: "0",
      es_pequeno: false,
      pequeno_forzado: false,
    },
  ],
};
